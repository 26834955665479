/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubAnalystDowngradeOptions } from '../models/tub-analyst-downgrade-options';
import { TubAnalystEscalationResponse } from '../models/tub-analyst-escalation-response';
import { TubAnalystEscalationOptions } from '../models/tub-analyst-escalation-options';
@Injectable({
  providedIn: 'root',
})
class AnalystsService extends __BaseService {
  static readonly AnalystsAccessCodesPath = '/analysts/{userIdOrEmail}/access-codes';
  static readonly AnalystsDowngradeUserFromAnalystPath = '/analysts/downgrade-from-analyst';
  static readonly AnalystsEscalateUserToAnalystPath = '/analysts/escalate-to-analyst';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * returns array of access codes for user
   *
   * Returns array of access codes linked to an Analyst user.
   * @param userIdOrEmail email address of the user or the ID for the user
   * @return Ok
   */
  AnalystsAccessCodesResponse(userIdOrEmail: string): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/analysts/${encodeURIComponent(String(userIdOrEmail))}/access-codes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * returns array of access codes for user
   *
   * Returns array of access codes linked to an Analyst user.
   * @param userIdOrEmail email address of the user or the ID for the user
   * @return Ok
   */
  AnalystsAccessCodes(userIdOrEmail: string): __Observable<Array<string>> {
    return this.AnalystsAccessCodesResponse(userIdOrEmail).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * downgrades a user from an analyst to a regular user
   *
   * Downgrades a user from an analyst, removing their access codes and removing them from the analyst group,
   * removing their access from Domo dashboards.
   * @param options the options for the downgrade, e.g email address
   */
  AnalystsDowngradeUserFromAnalystResponse(options: TubAnalystDowngradeOptions): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = options;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/analysts/downgrade-from-analyst`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * downgrades a user from an analyst to a regular user
   *
   * Downgrades a user from an analyst, removing their access codes and removing them from the analyst group,
   * removing their access from Domo dashboards.
   * @param options the options for the downgrade, e.g email address
   */
  AnalystsDowngradeUserFromAnalyst(options: TubAnalystDowngradeOptions): __Observable<null> {
    return this.AnalystsDowngradeUserFromAnalystResponse(options).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * escalates a user to an analyst
   *
   * Escalates a user to an analyst, with the access codes given applied as a permanent filter to their Domo dashboard
   * @param options the options for the escalation, e.g access code list, email address
   * @return User was updated or created.
   */
  AnalystsEscalateUserToAnalystResponse(options: TubAnalystEscalationOptions): __Observable<__StrictHttpResponse<TubAnalystEscalationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = options;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/analysts/escalate-to-analyst`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubAnalystEscalationResponse>;
      })
    );
  }
  /**
   * escalates a user to an analyst
   *
   * Escalates a user to an analyst, with the access codes given applied as a permanent filter to their Domo dashboard
   * @param options the options for the escalation, e.g access code list, email address
   * @return User was updated or created.
   */
  AnalystsEscalateUserToAnalyst(options: TubAnalystEscalationOptions): __Observable<TubAnalystEscalationResponse> {
    return this.AnalystsEscalateUserToAnalystResponse(options).pipe(
      __map(_r => _r.body as TubAnalystEscalationResponse)
    );
  }
}

module AnalystsService {
}

export { AnalystsService }
